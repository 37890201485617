import React from "react";
// contexte global (this.props.data)
import { WithContext } from "../store/DataProvider.js";
// router
import { withRouter } from "react-router-dom";
// custom filter
import { sort, getFilters, filter } from "../utils/filter.js";
// react-bootstrap components
import {
  Button,
  Collapse,
  ButtonToolbar,
  ListGroup,
  Form,
} from "react-bootstrap";
// react-select component
import Select from "react-select";
import { selectGreenStyle } from "styles/react-select";
// react-icons
import { FaSlidersH } from "react-icons/fa";
import { FaSortAmountDown } from "react-icons/fa";
import { FaSortAmountUp } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";

const defaultSort = {
  property: "",
  value: 0,
};

class Filters extends React.Component {
  constructor(props) {
    super(props);
    const config = filtersData[props.listName];
    this.state = {
      listName: config.listName,
      isFilterOpen: false,
      isSortOpen: false,
      isDeleteMode: false,
      filterNames: config.filters.map((filter) => filter.property),
      activeFilters: generateFilters(config.filters),
      activeSort: config.defaultSort || { ...defaultSort },
      list: this.props.list.map((parcelle) => {
        parcelle.properties.isActive = false;
        return parcelle;
      }),
    };
  }

  componentDidMount() {
    const historyState = this.props.history.state;
    const pathName = this.props.pathName;
    // si filtres précédemment activés par l'utilisateur
    if (historyState && historyState[pathName]) {
      // utiliser les filtres utilisateur
      this.updateFilters(historyState[pathName]);
    }
  }

  componentWillUnmount() {
    if (this.props.saveFilters) {
      const pathName = this.props.pathName;
      if (!this.props.history.state) {
        this.props.history.state = {};
      }
      // sauvegarder les filtres pendant toute la session utilisateur
      this.props.history.state[pathName] = {
        isSortOpen: this.state.isSortOpen,
        isFilterOpen: this.state.isFilterOpen,
        activeSort: this.state.activeSort,
        activeFilters: this.state.activeFilters,
      };
    }
  }

  toggle = (propertyName) => {
    this.setState({
      [propertyName]: !this.state[propertyName],
    });
  };
  isSort = () => {
    return this.state.activeSort.value > 0;
  };
  isFilter = () => {
    return this.state.filterNames.some((name) => {
      if (name !== "date") {
        return this.state.activeFilters[name].length > 0;
      } else {
        return (
          this.state.activeFilters[name][0].start ||
          this.state.activeFilters[name][1].end
        );
      }
    });
  };
  setSort = (property) => {
    let s = { ...this.state.activeSort };
    if (!s.property || s.property === property) {
      // si asc ou desc
      if (s.value < 2) {
        s.property = property;
        s.value = s.value + 1;
        // tri désactivé
      } else {
        s.property = "";
        s.value = 0;
      }
      // tri sur une propriété différente
    } else {
      s.property = property;
      s.value = 1;
    }

    this.setState({ activeSort: { property: s.property, value: s.value } });
  };
  setFilter = (propertyName, selectedOptions) => {
    this.setState((state) => {
      state.activeFilters[propertyName] = selectedOptions;
      if (propertyName === "groupesParcelles2") {
        if (selectedOptions.length > 0) {
          // récupérer toutes les parcelles
          let parcelles = [];
          selectedOptions.forEach((o) => {
            o.parcelles.forEach((p) => {
              parcelles.push(p);
            });
          });
          // enlever les potentiels doublons
          parcelles = Array.from(new Set(parcelles.map((p) => p.id))).map(
            (id) => {
              return parcelles.find((p) => p.id === id);
            }
          );
          // positionner les parcelles correspondantes aux groupes de parcelles
          state.activeFilters["id"] = parcelles;
        } else {
          state.activeFilters["id"] = [];
        }
      }
      return state;
    });
  };
  setDateFilter = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let index = name === "start" ? 0 : 1;

    this.setState((state) => {
      state.activeFilters.date[index][name] = value;
      return state;
    });
  };
  filter = (list) => {
    return sort(
      filter(
        list || [],
        getFilters(this.state.filterNames, this.state.activeFilters)
      ),
      this.state.activeSort.property,
      this.state.activeSort.value
    );
  };
  updateFilters = (filters) => {
    this.setState({
      isSortOpen: filters.isSortOpen,
      isFilterOpen: filters.isFilterOpen,
      activeSort: filters.activeSort,
      activeFilters: filters.activeFilters,
    });
  };
  resetFilters = () => {
    const config = filtersData[this.props.listName];
    this.setState({
      activeSort: config.defaultSort || { ...defaultSort },
      activeFilters: generateFilters(config.filters),
    });
  };
  toggleActive = (parcelleId) => {
    let parcelles = JSON.parse(JSON.stringify(this.state.list));

    const parcelle = parcelles.find(
      (parcelle) => parcelle.properties.id === parcelleId
    );

    if (parcelle) {
      parcelle.properties.isActive = !parcelle.properties.isActive;
      this.setState({
        list: parcelles,
      });
    }
  };
  getChildrenProps = () => {
    const childrenProps = {};
    const { isDeleteMode } = this.state;

    // pour listePArcelles - <Parcelles2 />
    if (this.props.deleteButton) {
      // liste triée par composant actuel avec les props isActive
      childrenProps.list = this.filter(this.state.list);
      // permet de changer la couleur de highlight dans <Parcelles2 />
      childrenProps.isDeleteMode = isDeleteMode;
      // si mode suppression multiple activé
      if (isDeleteMode) {
        // handleClick = toggleActive (prop parcelle isActive = !isActive)
        childrenProps.handleClick = (id) => this.toggleActive(id);
      } else {
        // handleClick = fonction définie par le parent
        childrenProps.handleClick = this.props.handleClick;
      }
      // pour assolement - <Parcelles2 />
    } else {
      // liste triée par composant parent avec les props isActive
      childrenProps.list = this.filter(this.props.list);
      // ne pas écraser handleClick car déjà défini directement sur <Parcelles2 />
    }
    return childrenProps;
  };

  getParcellesActive = () => {
    return this.state.list.filter((p) => p.properties.isActive);
  };

  resetParcellesActiveProps = () => {
    this.setState((state) => {
      state.list = state.list.map((parcelle) => {
        parcelle.properties.isActive = false;
        return parcelle;
      });
      return state;
    });
  };

  bulkDelete = async () => {
    const parcellesToDelete = this.getParcellesActive();
    if (parcellesToDelete.length > 0) {
      const promises = [];
      parcellesToDelete.forEach((p) => {
        promises.push(this.props.data.removeItem("parcelles", p.properties.id));
      });
      const responses = await Promise.all(promises);
      responses.forEach((r, index) => {
        if (r.error && r.error === "intervention") {
          const parcelleName = parcellesToDelete[index].properties.label;
          this.props.data.showNotification({
            type: "danger",
            title: `La parcelle "${parcelleName}" n'a pas pu être supprimée car elle est attachée à une ou plusieurs interventions.`,
          });
        }
      });
      this.props.data.sync(false);
    } else {
      this.props.data.showNotification({
        type: "warning",
        title: "Aucune parcelle n'a été sélectionnée",
      });
    }
  };

  render() {
    const list = this.filter(this.state.list);
    const config = filtersData[this.props.listName];
    const sortProperty = this.state.activeSort.property;
    const sortValue = this.state.activeSort.value;
    const { isDeleteMode } = this.state;
    return (
      <>
        <ListGroup.Item className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">
            {this.state.listName} ({list.length})
          </h4>
          <div>
            {this.props.deleteButton && (
              <Button
                variant={isDeleteMode ? "danger" : "outline-danger"}
                onClick={
                  isDeleteMode
                    ? () => {
                        this.resetParcellesActiveProps();
                        this.toggle("isDeleteMode");
                      }
                    : () => this.toggle("isDeleteMode")
                }
                className="float-right ml-2"
              >
                {isDeleteMode ? "Annuler" : "Supprimer"}
                {!isDeleteMode && <FaTrash className="ml-2" />}
              </Button>
            )}
            <Button
              variant={this.isSort() ? "primary" : "outline-primary"}
              onClick={() => this.toggle("isSortOpen")}
              className="float-right ml-2"
            >
              {this.isSort() ? "Tri actif" : "Trier"}
              <FaSortAmountDown className="ml-2" />
            </Button>
            <Button
              variant={this.isFilter() ? "success" : "outline-success"}
              onClick={() => this.toggle("isFilterOpen")}
              className="float-right"
            >
              {this.isFilter() ? "Filtre actif" : "Filtrer"}
              <FaSlidersH className="ml-2" />
            </Button>
          </div>
        </ListGroup.Item>
        <Collapse in={isDeleteMode}>
          <div className="mt-2 mb-2">
            <h5 className="text-danger">Mode suppression actif</h5>
            <h6 className="text-danger">
              - supprimer des parcelles : cliquez sur les parcelles à supprimer,
              puis bouton Supprimer
            </h6>
            <h6 className="text-danger">
              - sortir du mode suppression : cliquez sur Annuler
            </h6>
            <div className="d-flex justify-content-end">
              <Button variant="danger" onClick={() => this.bulkDelete()}>
                {`supprimer (${this.getParcellesActive().length})`}
                <FaTrash className="ml-2" />
              </Button>
            </div>
          </div>
        </Collapse>
        <Collapse in={this.state.isSortOpen}>
          <div>
            <div className="mt-2">
              <h5 className="text-primary">Trier par</h5>
              <ButtonToolbar>
                {config.sorts.map((sort, index) => (
                  <Button
                    key={index}
                    variant={
                      sortProperty === sort.property
                        ? "primary"
                        : "outline-primary"
                    }
                    className="mr-2 mb-2"
                    onClick={() => this.setSort(sort.property)}
                  >
                    {sort.placeholder}
                    {sortProperty === sort.property && sortValue === 1 && (
                      <FaSortAmountUp size={14} className="ml-2" />
                    )}
                    {sortProperty === sort.property && sortValue === 2 && (
                      <FaSortAmountDown size={14} className="ml-2" />
                    )}
                  </Button>
                ))}
              </ButtonToolbar>
            </div>
          </div>
        </Collapse>
        <Collapse in={this.state.isFilterOpen}>
          <div>
            <div className="mt-2 mb-2">
              <h5 className="text-success">Filtrer par</h5>
              {config.filters.map((filter, index) => {
                // TODO : remove || [] (données pas encore fetch)
                let options = this.props.data[filter.options] || [];
                return filter.property !== "date" ? (
                  <Select
                    key={index}
                    isMulti
                    placeholder={filter.placeholder}
                    getOptionValue={(opt) => (opt.id ? opt.id : opt.value)}
                    options={
                      !filter.properties
                        ? options
                        : options.map((item) => item["properties"])
                    }
                    value={this.state.activeFilters[filter.property]}
                    onChange={this.setFilter.bind(this, filter.property)}
                    className="mb-2"
                    styles={selectGreenStyle}
                  />
                ) : (
                  /* <Row key={index}>
                    <Col lg={6}> */
                  <Form.Control
                    key={index}
                    name={filter.type}
                    type="date"
                    placeholder="jj/mm/aaaa"
                    onChange={this.setDateFilter}
                    style={{ color: "#28a745" }}
                    className="border-success mb-2"
                  />
                  /* </Col>
                  </Row> */
                );
              })}
              {this.isFilter() && (
                <div className="d-flex justify-content-end">
                  <Button variant="danger" onClick={() => this.resetFilters()}>
                    Supprimer tous les filtres
                    {<GiCancel size={24} className="ml-2" />}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Collapse>
        {React.cloneElement(this.props.children, this.getChildrenProps())}
      </>
    );
  }
}

export default withRouter(WithContext(Filters));

const filtersData = {
  ilots: {
    listName: "Îlots",
    sorts: [
      { placeholder: "Nom", property: "label" },
      { placeholder: "Surface", property: "surface" },
    ],
    filters: [
      {
        placeholder: "Îlots",
        property: "id",
        options: "ilots",
        properties: true,
      },
    ],
  },
  parcelles: {
    listName: "Parcelles",
    sorts: [
      { placeholder: "Nom", property: "label" },
      { placeholder: "Surface", property: "surface" },
    ],
    filters: [
      {
        placeholder: "Groupe de parcelles",
        property: "groupesParcelles2",
        options: "groupesParcelles2",
      },
      {
        placeholder: "Parcelles",
        property: "id",
        options: "parcelles",
        properties: true,
      },
      {
        placeholder: "Cultures",
        property: "culture",
        options: "cultures",
      },
      {
        placeholder: "Variétés",
        property: "varietes",
        options: "varietes",
      },
      {
        placeholder: "Cultures précédentes",
        property: "culturePrecedente",
        options: "culturesPrecedentes",
      },
      {
        placeholder: "Types de culture",
        property: "typeCulture",
        options: "typeCulture",
      },
      {
        placeholder: "Destinations",
        property: "destination",
        options: "destination",
      },
    ],
  },
  selectionParcelles: {
    listName: "Parcelles",
    sorts: [
      { placeholder: "Nom", property: "label" },
      { placeholder: "Surface", property: "surface" },
    ],
    filters: [
      {
        placeholder: "Groupe de parcelles",
        property: "groupesParcelles2",
        options: "groupesParcelles2",
      },
      {
        placeholder: "Parcelles",
        property: "id",
        options: "parcelles",
        properties: true,
      },
      {
        placeholder: "Cultures",
        property: "culture",
        options: "cultures",
      },
      {
        placeholder: "Précédent",
        property: "culturePrecedente",
        options: "culturesPrecedentes",
      },
      {
        placeholder: "Variétés",
        property: "varietes",
        options: "varietes",
      },
    ],
  },
  interventions: {
    listName: "Interventions",
    defaultSort: {
      property: "date",
      value: 2,
    },
    sorts: [
      { placeholder: "Date", property: "date" },
      { placeholder: "Statut", property: "statutId" },
    ],
    filters: [
      {
        placeholder: "Types d'intervention",
        property: "typeId",
        options: "typesIntervention",
      },
      {
        placeholder: "Statuts",
        property: "statutId",
        options: "listeStatuts",
      },
      {
        placeholder: "Date début",
        property: "date",
        type: "start",
      },
      {
        placeholder: "Date fin",
        property: "date",
        type: "end",
      },
      {
        placeholder: "Parcelles",
        property: "parcelles",
        options: "parcelles",
        properties: true,
      },
      {
        placeholder: "Produits",
        property: "produits",
        options: "produits",
      },
    ],
  },
};

const generateFilters = (filters) => {
  let filtersObj = {};
  filters.forEach((filter) => {
    if (filter.property !== "date") {
      filtersObj[filter.property] = [];
    } else {
      filtersObj[filter.property] = [{ start: "" }, { end: "" }];
    }
  });
  return filtersObj;
};
